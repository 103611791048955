import React from 'react'

import { motion } from 'framer-motion'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'

import { Logo } from './logo'

export const DashboardLoading = () => {
  return (
    <Grid
      container
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
      }}
    >
      <Grid item>
        <Box
          sx={{
            marginTop: '-64px',
          }}
        >
          <motion.div
            animate={{
              scale: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
            }}
            transition={{
              loop: Infinity,
              ease: 'linear',
              duration: 1.2,
            }}
          >
            <Logo
              sx={{
                width: 150,
                objectFit: 'contain',
              }}
            />
          </motion.div>
        </Box>
      </Grid>
    </Grid>
  )
}
