import { FC, ReactNode, useLayoutEffect, useState } from 'react'

import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { scope } from '@realty/portal'

import { useAuth } from '../../store/useAuth'

const scopes = scope.config

const routes = [
  {
    path: '/dashboard',
    scope: scopes.General.Leads.VIEW,
  },
  {
    path: '/leads',
    scope: scopes.General.Leads.VIEW,
  },
  {
    path: '/account?tab=general',
    scope: scopes.Management.Members.ACCOUNT_MANAGE,
  },
  {
    scope: scopes.Management.Members.VIEW,
    path: '/members',
  },

  {
    scope: scopes.Management.Members.CREATE,
    path: '/members/create',
  },
  {
    scope: scopes.Management.Onboard.CREATE_WIZARD,
    path: '/onboard',
  },

  {
    scope: scopes.Management.Onboard.CONE_ZONE,
    path: '/cone-zone',
  },

  {
    scope: scopes.Management.Brands.VIEW,
    path: '/brands',
  },
  {
    scope: scopes.Management.Brands.CREATE,
    path: '/create',
  },
  {
    scope: scopes.Management.Groups.VIEW,
    path: '/groups',
  },
  {
    scope: scopes.Global.Settings.ACCESS,
    path: '/settings',
  },

  {
    scope: scopes.Management.Roles.LIST,
    path: '/roles',
  },
  {
    scope: scopes.Management.Profile.VIEW_MODIFY,
    path: '/profile',
  },
  {
    scope: scopes.Management.Profile.BLOG,
    path: '/blog',
  },
]

interface AuthGuardProps {
  children: ReactNode
}

export const AuthGuard: FC<AuthGuardProps> = props => {
  const { children } = props
  const isAuthenticated = useAuth(state => state.isAuthenticated)
  const user = useAuth(state => state.user)
  const router = useRouter()
  const [checked, setChecked] = useState(false)

  // const role = user?.level;

  // let allowed = true;

  // if (router.pathname.startsWith('/super_admin') && role !== 'super_admin') {
  // 	allowed = false;
  // }
  // if (router.pathname.startsWith('/member') && role !== 'member') {
  // 	allowed = false;
  // }
  // if (router.pathname.startsWith('/brand_admin') && role !== 'brand_admin') {
  // 	allowed = false;
  // }

  useLayoutEffect(
    () => {
      if (!router.isReady) {
        return
      }

      // if (router.pathname === '/') {
      //   router.replace(`/${role}/dashboard`);
      // }
      // if (!allowed) {
      // 	window.location.href = '/auth/login?guard=true';
      // }
      if (isAuthenticated) {
        // const lastPath = localStorage.getItem('lastPath');
        // localStorage.removeItem('lastPath');

        // if (lastPath) {
        //   router.replace(lastPath);
        // }
        for (const route of routes) {
          if (router.pathname.includes(route.path)) {
            if (!user?.scopes?.includes(route.scope)) {
              for (const route of routes) {
                if (user?.scopes?.includes(route.scope!)) {
                  router.replace(route.path)
                  break
                }
              }
              break
            }
          }
        }

        setChecked(true)
        // }
      } else {
        localStorage.setItem('lastPath', router.asPath)
        router.push('/auth/login?guard=true')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady],
  )

  if (!checked) {
    return null
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node,
}
