import { DashboardLoading } from 'components/dashboard-loading'
import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import Router from 'next/router'
import { useLayoutEffect } from 'react'

import { useAuth } from 'store/useAuth'
import { getTenantName } from 'tenant'

import { AuthGuard } from '../components/authentication/auth-guard'
import { DashboardLayout } from '../components/dashboard/dashboard-layout'
import styles from '../styles/Home.module.css'
import { scope } from '@realty/portal'

const scopes = scope.config

const routes = [
  {
    path: '/launchpad',
    scope: scopes.General.Leads.VIEW,
  },
  {
    path: '/dashboard',
    scope: scopes.General.Leads.VIEW,
  },
  {
    path: '/leads',
    scope: scopes.General.Leads.VIEW,
  },
  {
    path: '/account?tab=general',
    scope: scopes.Management.Members.ACCOUNT_MANAGE,
  },
  {
    scope: scopes.Management.Members.VIEW,
    path: '/members',
  },

  {
    scope: scopes.Management.Members.CREATE,
    path: '/members/create',
  },
  {
    scope: scopes.Management.Onboard.CREATE_WIZARD,
    path: '/onboard',
  },

  {
    scope: scopes.Management.Brands.VIEW,
    path: '/brands',
  },
  {
    scope: scopes.Management.Brands.CREATE,
    path: '/create',
  },
  {
    scope: scopes.Management.Groups.VIEW,
    path: '/groups',
  },
  {
    scope: scopes.Global.Settings.ACCESS,
    path: '/settings',
  },

  {
    scope: scopes.Management.Roles.LIST,
    path: '/roles',
  },
]

const Home: NextPage = () => {
  const user = useAuth(state => state.user)

  useLayoutEffect(() => {
    for (const route of routes) {
      if (user?.scopes?.includes(route.scope!)) {
        // if (
        //   route.path === '/dashboard' &&
        //   user.scopes.includes('General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS')
        // ) {
        //   Router.replace('/leads')
        //   break
        // }
        if (route.path === '/launchpad') {
          if (getTenantName() === 'homescout') {
            Router.replace(route.path)
            break
          }
          continue
        }

        Router.replace(route.path)
        break
      }
    }
  }, [])

  // Router.replace('/404');

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return <DashboardLoading />
  return (
    <div className={styles.container}>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>
          Welcome to <a href="https://nextjs.org">Next.js!</a>
        </h1>

        <p className={styles.description}>
          Get started by editing{' '}
          <code className={styles.code}>pages/index.tsx</code>
        </p>

        <div className={styles.grid}>
          <a href="https://nextjs.org/docs" className={styles.card}>
            <h2>Documentation &rarr;</h2>
            <p>Find in-depth information about Next.js features and API.</p>
          </a>

          <a href="https://nextjs.org/learn" className={styles.card}>
            <h2>Learn &rarr;</h2>
            <p>Learn about Next.js in an interactive course with quizzes!</p>
          </a>

          <a
            href="https://github.com/vercel/next.js/tree/master/examples"
            className={styles.card}
          >
            <h2>Examples &rarr;</h2>
            <p>Discover and deploy boilerplate example Next.js projects.</p>
          </a>

          <a
            href="https://vercel.com/new?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
            className={styles.card}
          >
            <h2>Deploy &rarr;</h2>
            <p>
              Instantly deploy your Next.js site to a public URL with Vercel.
            </p>
          </a>
        </div>
      </main>

      <footer className={styles.footer}>
        <a
          href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Powered by{' '}
          <span className={styles.logo}>
            <Image src="/vercel.svg" alt="Vercel Logo" width={72} height={16} />
          </span>
        </a>
      </footer>
    </div>
  )
}

Home.getLayout = page => (
  <AuthGuard>
    <DashboardLayout>{page}</DashboardLayout>
  </AuthGuard>
)

export default Home
